import axios from 'axios';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import multiSearchFilterExtender from '../JSON/ProductSearch/multiSearchFilter.json';
import {
    FED_MAX_IMPERIAL,
    FED_MAX_METRIC,
    LANGUAGES_IMPERIAL_UNITS,
    MOTOR_POWER_MAX_IMPERIAL,
    MOTOR_POWER_MAX_METRIC,
} from '../constants/commonConstants';
import { CURRENT_PRODUCTS_LANGUAGE } from '../constants/localStorageKeys';
import {
    BOGE_FILTERS_ARRAYS,
    BOGE_FILTERS_BOOLEANS,
    BOGE_FILTERS_NUMBERS,
    BOGE_FILTERS_STRINGS,
} from '../constants/productSearchSessionName';
import {
    FiltersArraysInterface,
    FiltersBooleansInterface,
    FiltersNumbersInterface,
    FiltersStringsInterface,
} from '../interfaces/productSearch';
import { categoryNamesWithIdObject } from '../templateObjects/categoryNamesWithIdObject';
import {
    filtersArraysExample,
    filtersBooleansExample,
    filtersNumbersExample,
    filtersStringsExample,
} from '../templateObjects/productSearchObjects';
import { filterItems } from '../utils/filterItems';
import {
    getFilterArraySessionStorage,
    getFilterBooleanSessionStorage,
    getFilterNumberSessionStorage,
    getFilterStringFromSessionStorage,
    getIsProductsSearchFiltersOpened,
} from '../utils/getDataFromSessionStorage';
import { getSiteUrl } from '../utils/getSiteUrl';
import { getCategoryId, getCategoryName, urlHasProductSearchParams } from '../utils/productSearchUtils';

interface ProductSearchResultsContextInterface {
    itemsOfProducts: Array<unknown>;
    setItemsOfProducts: Dispatch<SetStateAction<Array<unknown>>>;
    numberOfResults: number;
    setNumberOfResults: Dispatch<SetStateAction<number>>;
    searchFilter: string;
    setSearchFilter: Dispatch<SetStateAction<string>>;
    typeFilter: string;
    setTypeFilter: Dispatch<SetStateAction<string>>;
    modeFilter: string;
    setModeFilter: Dispatch<SetStateAction<string>>;
    pressureRangeFilter: number;
    setPressureRangeFilter: Dispatch<SetStateAction<number>>;
    heatRecoveryFilter: number;
    setHeatRecoveryFilter: Dispatch<SetStateAction<number>>;
    soundAbsorptionFilter: Array<string>;
    setSoundAbsorptionFilter: Dispatch<SetStateAction<Array<string>>>;
    deliveryControlFilter: Array<string>;
    setDeliveryControlFilter: Dispatch<SetStateAction<Array<string>>>;
    coolingFilter: Array<string>;
    setCoolingFilter: Dispatch<SetStateAction<Array<string>>>;
    otherFilter: Array<string>;
    setOtherFilter: Dispatch<SetStateAction<Array<string>>>;
    currentCategoryName: string;
    setCurrentCategoryName: Dispatch<SetStateAction<string>>;
    currentCategoryId: string;
    setCurrentCategoryId: Dispatch<SetStateAction<string>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    currentOffset: number;
    setCurrentOffset: Dispatch<SetStateAction<number>>;
    compressionModeUnits: string;
    setCompressionModeUnits: Dispatch<SetStateAction<string>>;
    motorPowerMinFilter: number;
    setMotorPowerMinFilter: Dispatch<SetStateAction<number>>;
    motorPowerMaxFilter: number;
    setMotorPowerMaxFilter: Dispatch<SetStateAction<number>>;
    fedMinFilter: number;
    setFedMinFilter: Dispatch<SetStateAction<number>>;
    fedMaxFilter: number;
    setFedMaxFilter: Dispatch<SetStateAction<number>>;
    isFiltersSubmit: boolean;
    setIsFiltersSubmit: Dispatch<SetStateAction<boolean>>;
    isMobileView: boolean;
    setIsMobileView: Dispatch<SetStateAction<boolean>>;
    isProductsSearchFiltersOpened: boolean;
    setIsProductsSearchFiltersOpened: Dispatch<SetStateAction<boolean>>;
    categoryNamesWithId: any;
    setCategoryNamesWithId: Dispatch<SetStateAction<any>>;
    isAllFiltersClear: boolean;
    setIsAllFiltersClear: Dispatch<SetStateAction<boolean>>;
    motorPowerMaxValue: number;
    setMotorPowerMaxValue: Dispatch<SetStateAction<number>>;
    fedMaxValue: number;
    setFedMaxValue: Dispatch<SetStateAction<number>>;
}

export const ProductSearchResultsContext = createContext<Partial<ProductSearchResultsContextInterface>>({
    itemsOfProducts: [],
    setItemsOfProducts: () => {},
    numberOfResults: 0,
    setNumberOfResults: () => {},
    searchFilter: '',
    setSearchFilter: () => {},
    typeFilter: '',
    setTypeFilter: () => {},
    modeFilter: '',
    setModeFilter: () => {},
    pressureRangeFilter: 0,
    setPressureRangeFilter: () => {},
    heatRecoveryFilter: 0,
    setHeatRecoveryFilter: () => {},
    soundAbsorptionFilter: [],
    setSoundAbsorptionFilter: () => {},
    deliveryControlFilter: [],
    setDeliveryControlFilter: () => {},
    coolingFilter: [],
    setCoolingFilter: () => {},
    otherFilter: [],
    setOtherFilter: () => {},
    currentCategoryName: '',
    setCurrentCategoryName: () => {},
    currentCategoryId: '',
    setCurrentCategoryId: () => {},
    isLoading: false,
    setIsLoading: () => {},
    currentOffset: 0,
    setCurrentOffset: () => {},
    compressionModeUnits: '',
    setCompressionModeUnits: () => {},
    motorPowerMinFilter: 0,
    setMotorPowerMinFilter: () => {},
    motorPowerMaxFilter: 0,
    setMotorPowerMaxFilter: () => {},
    fedMinFilter: 0,
    setFedMinFilter: () => {},
    fedMaxFilter: 0,
    setFedMaxFilter: () => {},
    isFiltersSubmit: false,
    setIsFiltersSubmit: () => {},
    isMobileView: false,
    setIsMobileView: () => {},
    isProductsSearchFiltersOpened: false,
    setIsProductsSearchFiltersOpened: () => {},
    categoryNamesWithId: categoryNamesWithIdObject,
    setCategoryNamesWithId: () => {},
    isAllFiltersClear: false,
    setIsAllFiltersClear: () => {},
    motorPowerMaxValue: 0,
    setMotorPowerMaxValue: () => {},
    fedMaxValue: 0,
    setFedMaxValue: () => {},
});

export const ProductSearchResultsProvider = ({ children }) => {
    const siteUrl = getSiteUrl();
    const { language } = useI18next();
    const url_string = typeof window !== 'undefined' ? window.location.href : siteUrl;
    const urlParamsList = url_string.split('?');
    let hasUrlParams = urlHasProductSearchParams(url_string, urlParamsList);
    const queryString = typeof window !== 'undefined' ? window.location.search : siteUrl;
    const urlParams = new URLSearchParams(queryString);
    const [categoryNamesWithId, setCategoryNamesWithId] = useState([]);
    const [motorPowerMaxValue, setMotorPowerMaxValue] = useState(
        LANGUAGES_IMPERIAL_UNITS.indexOf(language) !== -1 ? MOTOR_POWER_MAX_IMPERIAL : MOTOR_POWER_MAX_METRIC
    );
    const [fedMaxValue, setFedMaxValue] = useState(
        LANGUAGES_IMPERIAL_UNITS.indexOf(language) !== -1 ? FED_MAX_IMPERIAL : FED_MAX_METRIC
    );
    const [itemsOfProducts, setItemsOfProducts] = useState(
        !hasUrlParams ? getFilterArraySessionStorage('itemsOfProducts') : []
    );
    const [numberOfResults, setNumberOfResults] = useState(
        !hasUrlParams ? getFilterNumberSessionStorage('numberOfResults') : 0
    );
    const [searchFilter, setSearchFilter] = useState(
        hasUrlParams
            ? urlParams?.has('title')
                ? urlParams.get('title')
                : ''
            : getFilterStringFromSessionStorage('searchFilter')
    );
    const [typeFilter, setTypeFilter] = useState(
        hasUrlParams
            ? urlParams?.has('field_product_compressor_type')
                ? urlParams.get('field_product_compressor_type')
                : urlParams?.has('field_product_compressor_type_tid') &&
                  multiSearchFilterExtender['field_product_compressor_type_tid'][
                      urlParams.get('field_product_compressor_type_tid')
                  ]
                ? multiSearchFilterExtender['field_product_compressor_type_tid'][
                      urlParams.get('field_product_compressor_type_tid')
                  ]
                : ''
            : getFilterStringFromSessionStorage('typeFilter')
    );
    const [modeFilter, setModeFilter] = useState(
        hasUrlParams
            ? urlParams?.has('field_product_compression_mode')
                ? urlParams.get('field_product_compression_mode')
                : urlParams?.has('field_product_compression_mode_tid') &&
                  multiSearchFilterExtender['field_product_compression_mode_tid'][
                      urlParams.get('field_product_compression_mode_tid')
                  ]
                ? multiSearchFilterExtender['field_product_compression_mode_tid'][
                      urlParams.get('field_product_compression_mode_tid')
                  ]
                : ''
            : getFilterStringFromSessionStorage('modeFilter')
    );
    const [pressureRangeFilter, setPressureRangeFilter] = useState(
        hasUrlParams
            ? urlParams?.has('field_product_pressure_range')
                ? urlParams.get('field_product_pressure_range')
                : 0
            : getFilterNumberSessionStorage('pressureRangeFilter')
    );
    const [heatRecoveryFilter, setHeatRecoveryFilter] = useState(
        hasUrlParams
            ? urlParams?.has('field_product_heat_recovery')
                ? urlParams.get('field_product_heat_recovery')
                : 0
            : getFilterNumberSessionStorage('heatRecoveryFilter')
    );
    const [soundAbsorptionFilter, setSoundAbsorptionFilter] = useState(
        !hasUrlParams ? getFilterArraySessionStorage('soundAbsorptionFilter') : []
    );
    const [deliveryControlFilter, setDeliveryControlFilter] = useState(
        !hasUrlParams ? getFilterArraySessionStorage('deliveryControlFilter') : []
    );
    const [coolingFilter, setCoolingFilter] = useState(
        !hasUrlParams ? getFilterArraySessionStorage('coolingFilter') : []
    );
    const [otherFilter, setOtherFilter] = useState(!hasUrlParams ? getFilterArraySessionStorage('otherFilter') : []);
    const [currentCategoryName, setCurrentCategoryName] = useState(
        getCategoryName(urlParams, categoryNamesWithId, hasUrlParams)
    );
    const [currentCategoryId, setCurrentCategoryId] = useState(
        getCategoryId(urlParams, categoryNamesWithId, hasUrlParams)
    );
    const [isLoading, setIsLoading] = useState(!hasUrlParams ? getFilterBooleanSessionStorage('isLoading') : false);
    const [currentOffset, setCurrentOffset] = useState(
        !hasUrlParams ? getFilterNumberSessionStorage('currentOffset') : 0
    );
    const [isFirstFetch, setIsFirstFetch] = useState(
        !hasUrlParams ? getFilterBooleanSessionStorage('isFirstFetch') : false
    );
    const [compressionModeUnits, setCompressionModeUnits] = useState(
        urlParams?.has('compression_mode_units')
            ? urlParams?.get('compression_mode_units')
            : urlParams?.has('field_product_motor_power_kw_value[min]') ||
              urlParams?.has('field_product_fed_value[min]')
            ? 'metric'
            : !hasUrlParams
            ? getFilterStringFromSessionStorage('compressionModeUnits')
            : ''
    );
    const [motorPowerMinFilter, setMotorPowerMinFilter] = useState(
        hasUrlParams
            ? urlParams?.has('field_product_motor_power_kw_value[min]')
                ? urlParams.get('field_product_motor_power_kw_value[min]')
                : 0
            : getFilterNumberSessionStorage('motorPowerMinFilter')
    );
    const [motorPowerMaxFilter, setMotorPowerMaxFilter] = useState(
        hasUrlParams
            ? urlParams?.has('field_product_motor_power_kw_value[max]')
                ? urlParams.get('field_product_motor_power_kw_value[max]')
                : motorPowerMaxValue
            : getFilterNumberSessionStorage('motorPowerMaxFilter', motorPowerMaxValue) ?? motorPowerMaxValue
    );
    const [fedMinFilter, setFedMinFilter] = useState(
        hasUrlParams
            ? urlParams?.has('field_product_fed_value[min]')
                ? urlParams.get('field_product_fed_value[min]')
                : 0
            : getFilterNumberSessionStorage('fedMinFilter')
    );
    const [fedMaxFilter, setFedMaxFilter] = useState(
        hasUrlParams
            ? urlParams?.has('field_product_fed_value[max]')
                ? urlParams.get('field_product_fed_value[max]')
                : fedMaxValue
            : getFilterNumberSessionStorage('fedMaxFilter', fedMaxValue) ?? fedMaxValue
    );
    const [isFiltersSubmit, setIsFiltersSubmit] = useState(
        !hasUrlParams ? getFilterBooleanSessionStorage('isFiltersSubmit') : false
    );
    const [isMobileView, setIsMobileView] = useState(getFilterBooleanSessionStorage('isMobileView'));
    const [isProductsSearchFiltersOpened, setIsProductsSearchFiltersOpened] = useState(
        !hasUrlParams ? getFilterBooleanSessionStorage('isProductsSearchFiltersOpened') : false
    );
    const [allowToFetch, setAllowToFetch] = useState(getIsProductsSearchFiltersOpened());
    const [filtersStrings, setFiltersStrings] = useState<FiltersStringsInterface>(filtersStringsExample);
    const [filtersNumbers, setFiltersNumbers] = useState<FiltersNumbersInterface>(filtersNumbersExample);
    const [filtersArrays, setFiltersArrays] = useState<FiltersArraysInterface>(filtersArraysExample);
    const [filtersBooleans, setFiltersBooleans] = useState<FiltersBooleansInterface>(filtersBooleansExample);
    const [isAllFiltersClear, setIsAllFiltersClear] = useState(false);

    const setFiltersFromSessionStorage = (): void => {
        if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem(BOGE_FILTERS_STRINGS) !== null) {
            const filtersStringObject = JSON.parse(sessionStorage.getItem(BOGE_FILTERS_STRINGS));
            setSearchFilter(filtersStringObject?.searchFilter ?? '');
            setTypeFilter(filtersStringObject?.typeFilter ?? '');
            setModeFilter(filtersStringObject?.modeFilter ?? '');
            setCurrentCategoryName(filtersStringObject?.currentCategoryName ?? '');
            setCurrentCategoryId(filtersStringObject?.currentCategoryId ?? '');
            setCompressionModeUnits(filtersStringObject?.compressionModeUnits ?? '');
        }
        if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem(BOGE_FILTERS_NUMBERS) !== null) {
            const filtersNumbersObject = JSON.parse(sessionStorage.getItem(BOGE_FILTERS_NUMBERS));
            setNumberOfResults(filtersNumbersObject?.numberOfResults ?? 0);
            setPressureRangeFilter(filtersNumbersObject?.pressureRangeFilter ?? 0);
            setHeatRecoveryFilter(filtersNumbersObject?.heatRecoveryFilter ?? 0);
            setCurrentOffset(filtersNumbersObject?.currentOffset ?? 0);
            setMotorPowerMinFilter(filtersNumbersObject?.motorPowerMinFilter ?? 0);
            setMotorPowerMaxFilter(filtersNumbersObject?.motorPowerMaxFilter ?? motorPowerMaxValue);
            setFedMinFilter(filtersNumbersObject?.fedMinFilter ?? 0);
            setFedMaxFilter(filtersNumbersObject?.fedMaxFilter ?? fedMaxValue);
        }
        if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem(BOGE_FILTERS_ARRAYS) !== null) {
            const filtersArraysObject = JSON.parse(sessionStorage.getItem(BOGE_FILTERS_ARRAYS));
            setItemsOfProducts(filterItems(filtersArraysObject?.itemsOfProducts) ?? []);
            setSoundAbsorptionFilter(filtersArraysObject?.soundAbsorptionFilter ?? []);
            setDeliveryControlFilter(filtersArraysObject?.deliveryControlFilter ?? []);
            setCoolingFilter(filtersArraysObject?.coolingFilter ?? []);
            setOtherFilter(filtersArraysObject?.otherFilter ?? []);
        }
        if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem(BOGE_FILTERS_BOOLEANS) !== null) {
            const filtersBooleansObject = JSON.parse(sessionStorage.getItem(BOGE_FILTERS_BOOLEANS));
            setIsLoading(filtersBooleansObject?.isLoading ?? false);
            setIsFirstFetch(filtersBooleansObject?.isFirstFetch ?? false);
            setIsFiltersSubmit(filtersBooleansObject?.isFiltersSubmit ?? false);
            setIsMobileView(filtersBooleansObject?.isMobileView ?? false);
            setIsProductsSearchFiltersOpened(filtersBooleansObject?.isProductsSearchFiltersOpened ?? false);
        }
    };

    const clearFilters = (): void => {
        sessionStorage.removeItem(BOGE_FILTERS_ARRAYS);
        sessionStorage.removeItem(BOGE_FILTERS_BOOLEANS);
        sessionStorage.removeItem(BOGE_FILTERS_NUMBERS);
        sessionStorage.removeItem(BOGE_FILTERS_STRINGS);

        setAllowToFetch(false);
        setSearchFilter('');
        setTypeFilter('');
        setModeFilter('');
        setCurrentCategoryName('');
        setCurrentCategoryId('');
        setCompressionModeUnits('');
        setNumberOfResults(0);
        setPressureRangeFilter(0);
        setHeatRecoveryFilter(0);
        setCurrentOffset(0);
        setMotorPowerMinFilter(0);
        setMotorPowerMaxFilter(motorPowerMaxValue);
        setFedMinFilter(0);
        setFedMaxFilter(fedMaxValue);
        setItemsOfProducts([]);
        setSoundAbsorptionFilter([]);
        setDeliveryControlFilter([]);
        setCoolingFilter([]);
        setOtherFilter([]);
        setIsLoading(false);
        setIsFirstFetch(true);
        setIsFiltersSubmit(false);
        setIsMobileView(false);
        setIsProductsSearchFiltersOpened(false);
    };

    useEffect(() => {
        !hasUrlParams && setFiltersFromSessionStorage();
    }, []);

    useEffect(() => {
        const currentProductsLanguage = localStorage.getItem(CURRENT_PRODUCTS_LANGUAGE);
        if (currentProductsLanguage !== language) {
            localStorage.setItem(CURRENT_PRODUCTS_LANGUAGE, language);
            !hasUrlParams && clearFilters();
        }

        if (LANGUAGES_IMPERIAL_UNITS.indexOf(language) !== -1) {
            setMotorPowerMaxValue(MOTOR_POWER_MAX_IMPERIAL);
            setFedMaxValue(FED_MAX_IMPERIAL);
        } else {
            setMotorPowerMaxValue(MOTOR_POWER_MAX_METRIC);
            setFedMaxValue(FED_MAX_METRIC);
        }
    }, [language]);

    useEffect(() => {
        setFiltersStrings({
            searchFilter,
            typeFilter,
            modeFilter,
            currentCategoryName,
            currentCategoryId,
            compressionModeUnits,
        });
    }, [searchFilter, typeFilter, modeFilter, currentCategoryName, currentCategoryId, compressionModeUnits]);

    useEffect(() => {
        typeof sessionStorage !== 'undefined' &&
            sessionStorage.setItem(BOGE_FILTERS_STRINGS, JSON.stringify(filtersStrings));
    }, [filtersStrings]);

    useEffect(() => {
        setFiltersNumbers({
            numberOfResults,
            pressureRangeFilter,
            heatRecoveryFilter,
            currentOffset,
            motorPowerMinFilter,
            motorPowerMaxFilter,
            fedMinFilter,
            fedMaxFilter,
        });
    }, [
        numberOfResults,
        pressureRangeFilter,
        heatRecoveryFilter,
        currentOffset,
        motorPowerMinFilter,
        motorPowerMaxFilter,
        fedMinFilter,
        fedMaxFilter,
    ]);

    useEffect(() => {
        typeof sessionStorage !== 'undefined' &&
            sessionStorage.setItem(BOGE_FILTERS_NUMBERS, JSON.stringify(filtersNumbers));
    }, [filtersNumbers]);

    useEffect(() => {
        setFiltersArrays({
            itemsOfProducts: filterItems(itemsOfProducts),
            soundAbsorptionFilter,
            deliveryControlFilter,
            coolingFilter,
            otherFilter,
        });
    }, [itemsOfProducts, soundAbsorptionFilter, deliveryControlFilter, coolingFilter, otherFilter]);

    useEffect(() => {
        typeof sessionStorage !== 'undefined' &&
            sessionStorage.setItem(BOGE_FILTERS_ARRAYS, JSON.stringify(filtersArrays));
    }, [filtersArrays]);

    useEffect(() => {
        setFiltersBooleans({
            isLoading,
            isFirstFetch,
            isFiltersSubmit,
            isMobileView,
            isProductsSearchFiltersOpened,
        });
    }, [isLoading, isFirstFetch, isFiltersSubmit, isMobileView, isProductsSearchFiltersOpened]);

    useEffect(() => {
        typeof sessionStorage !== 'undefined' &&
            sessionStorage.setItem(BOGE_FILTERS_BOOLEANS, JSON.stringify(filtersBooleans));
    }, [filtersBooleans]);

    useEffect(() => {
        const onReloadPageHandle = () => sessionStorage.clear();
        if (typeof window !== 'undefined') {
            window.onbeforeunload = onReloadPageHandle;
        }
    }, []);

    const checkQueryArguments = () => {
        let varDeclaration = ['$offset: Int!', '$limit: Int!', '$language: String!'];
        let queryArgs = ['offset: $offset', 'limit: $limit', 'language: $language'];

        const addFilter = (name: string, type: string) => {
            varDeclaration.push(`$${name}: ${type}!`);
            queryArgs.push(`${name}: $${name}`);
        };

        const deleteFilter = (name: string, type: string) => {
            const index = varDeclaration.indexOf(`$${name}: ${type}!`);
            if (index > -1) {
                varDeclaration.splice(index, 1);
                queryArgs.splice(index, 1);
            }
        };

        if (typeFilter !== undefined && typeFilter.length > 0) {
            addFilter('type', 'String');
        } else {
            deleteFilter('type', 'String');
        }
        if (modeFilter !== undefined && modeFilter.length > 0) {
            addFilter('mode', 'String');
        } else {
            deleteFilter('mode', 'String');
        }
        if (searchFilter !== undefined && searchFilter.length > 0) {
            addFilter('search', 'String');
        } else {
            deleteFilter('search', 'String');
        }
        if (soundAbsorptionFilter !== undefined && soundAbsorptionFilter.length > 0) {
            addFilter('sound_absorption', 'String');
        } else {
            deleteFilter('sound_absorption', 'String');
        }
        if (pressureRangeFilter !== undefined && pressureRangeFilter !== 0 && pressureRangeFilter !== false) {
            addFilter('pressure_range', 'Int');
        } else {
            deleteFilter('pressure_range', 'String');
        }
        if (otherFilter !== undefined && otherFilter.length > 0) {
            addFilter('others', 'String');
        } else {
            deleteFilter('others', 'String');
        }
        if (heatRecoveryFilter !== undefined && heatRecoveryFilter !== 0 && heatRecoveryFilter !== false) {
            addFilter('heat_recovery', 'Int');
        } else {
            deleteFilter('heat_recovery', 'Int');
        }
        if (deliveryControlFilter !== undefined && deliveryControlFilter.length > 0) {
            addFilter('delivery_control', 'String');
        } else {
            deleteFilter('delivery_control', 'String');
        }
        if (coolingFilter !== undefined && coolingFilter.length > 0) {
            addFilter('cooling', 'String');
        } else {
            deleteFilter('cooling', 'String');
        }
        if (currentCategoryName !== undefined && currentCategoryName.length > 0) {
            addFilter('main', 'String');
        } else {
            deleteFilter('main', 'String');
        }
        if (
            compressionModeUnits !== undefined &&
            compressionModeUnits.length > 0 &&
            motorPowerMaxFilter <= motorPowerMaxValue
        ) {
            addFilter('motor_power_units', 'String');
        } else {
            deleteFilter('motor_power_units', 'String');
        }
        if (compressionModeUnits !== undefined && compressionModeUnits.length > 0 && fedMaxFilter <= fedMaxValue) {
            addFilter('fed_units', 'String');
        } else {
            deleteFilter('fed_units', 'String');
        }
        if (compressionModeUnits !== undefined && motorPowerMinFilter !== undefined && motorPowerMinFilter >= 0) {
            addFilter('motor_power_min', 'Int');
        } else {
            deleteFilter('motor_power_min', 'Int');
        }
        if (
            compressionModeUnits !== undefined &&
            motorPowerMaxFilter !== undefined &&
            motorPowerMaxFilter <= motorPowerMaxValue
        ) {
            addFilter('motor_power_max', 'Int');
        } else {
            deleteFilter('motor_power_max', 'Int');
        }
        if (compressionModeUnits !== undefined && fedMinFilter !== undefined && fedMinFilter >= 0) {
            addFilter('fed_min', 'Int');
        } else {
            deleteFilter('fed_min', 'Int');
        }
        if (compressionModeUnits !== undefined && fedMaxFilter !== undefined && fedMaxFilter <= fedMaxValue) {
            addFilter('fed_max', 'Int');
        } else {
            deleteFilter('fed_max', 'Int');
        }
        if (motorPowerMinFilter === 0 && motorPowerMaxFilter === motorPowerMaxValue) {
            deleteFilter('motor_power_min', 'Int');
            deleteFilter('motor_power_max', 'Int');
        }
        if (fedMinFilter === 0 && fedMaxFilter === fedMaxValue) {
            deleteFilter('fed_min', 'Int');
            deleteFilter('fed_max', 'Int');
        }
        return {
            varDeclaration: varDeclaration,
            queryArgs: queryArgs,
        };
    };

    const useFetchDataByFilters = async (
        varDeclaration: Array<unknown>,
        queryArgs: Array<unknown>
    ): Promise<{ items: Array<unknown>; total: number }> => {
        const data = await axios.post(`${process.env.DRUPAL_URL}/graphql`, {
            query: `
                    query searchByFilters(${varDeclaration}) {
                        product_search(${queryArgs}) {
                            schema
                            total
                            items {
                                image {
                                alt
                                field_media_image
                                }
                                title
                                id
                                url
                            }
                        }
                    }
                `,
            variables: {
                search: searchFilter,
                type: typeFilter,
                mode: modeFilter,
                sound_absorption: soundAbsorptionFilter.join(','),
                pressure_range: +pressureRangeFilter,
                others: otherFilter.join(','),
                heat_recovery: +heatRecoveryFilter,
                delivery_control: deliveryControlFilter.join(','),
                cooling: coolingFilter.join(','),
                main: currentCategoryId,
                motor_power_units: compressionModeUnits,
                fed_units: compressionModeUnits,
                motor_power_min: +motorPowerMinFilter,
                motor_power_max: +motorPowerMaxFilter,
                fed_min: +fedMinFilter,
                fed_max: +fedMaxFilter,
                offset: +currentOffset,
                limit: 16,
                language: language,
            },
        });

        return {
            items: data?.data?.data?.product_search?.items ?? [],
            total: data?.data?.data?.product_search?.total ?? 0,
        };
    };

    const fetchMoreProducts = async () => {
        if (allowToFetch) {
            setIsLoading(true);
            const { varDeclaration, queryArgs } = checkQueryArguments();
            const { items } = await useFetchDataByFilters(varDeclaration, queryArgs);
            setItemsOfProducts(itemsOfProducts.concat(items));
            setIsLoading(false);
            setIsFirstFetch(true);
        } else {
            setAllowToFetch(true);
        }
    };

    useEffect(() => {
        fetchMoreProducts();
    }, [currentOffset]);

    const fetchData = async (shouldFetch = false) => {
        if (allowToFetch || shouldFetch) {
            setIsLoading(true);
            setCurrentOffset(0);
            const { varDeclaration, queryArgs } = checkQueryArguments();
            const { items, total } = await useFetchDataByFilters(varDeclaration, queryArgs);
            setItemsOfProducts(items);
            setNumberOfResults(total);
            setIsFiltersSubmit(false);
            setIsAllFiltersClear(false);
            setIsLoading(false);
        } else {
            setAllowToFetch(true);
        }
    };

    useEffect(() => {
        if (isAllFiltersClear === true) {
            fetchData();
        }
    }, [isAllFiltersClear]);

    useEffect(() => {
        if (hasUrlParams) {
            fetchData(true);
            setIsProductsSearchFiltersOpened(true);
        }
        if (isMobileView) {
            if (
                isFiltersSubmit &&
                (typeFilter ||
                    modeFilter ||
                    searchFilter ||
                    soundAbsorptionFilter.length > 0 ||
                    pressureRangeFilter ||
                    otherFilter.length > 0 ||
                    heatRecoveryFilter ||
                    deliveryControlFilter.length > 0 ||
                    coolingFilter.length > 0 ||
                    currentCategoryId ||
                    currentCategoryName ||
                    motorPowerMinFilter >= 0 ||
                    motorPowerMaxFilter ||
                    fedMinFilter >= 0 ||
                    fedMaxFilter ||
                    isFirstFetch)
            ) {
                fetchData();
            }
        } else {
            if (
                typeFilter ||
                modeFilter ||
                searchFilter ||
                soundAbsorptionFilter.length > 0 ||
                pressureRangeFilter ||
                otherFilter.length > 0 ||
                heatRecoveryFilter ||
                deliveryControlFilter.length > 0 ||
                coolingFilter.length > 0 ||
                currentCategoryId ||
                currentCategoryName ||
                motorPowerMinFilter >= 0 ||
                motorPowerMaxFilter ||
                fedMinFilter >= 0 ||
                fedMaxFilter ||
                isFirstFetch
            ) {
                fetchData();
            }
        }
    }, [
        typeFilter,
        modeFilter,
        searchFilter,
        soundAbsorptionFilter,
        pressureRangeFilter,
        otherFilter,
        heatRecoveryFilter,
        deliveryControlFilter,
        coolingFilter,
        currentCategoryId,
        currentCategoryName,
        isFiltersSubmit,
        language,
        motorPowerMinFilter,
        motorPowerMaxFilter,
        fedMinFilter,
        fedMaxFilter,
    ]);

    return (
        <ProductSearchResultsContext.Provider
            value={{
                itemsOfProducts,
                setItemsOfProducts,
                numberOfResults,
                setNumberOfResults,
                searchFilter,
                setSearchFilter,
                typeFilter,
                setTypeFilter,
                modeFilter,
                setModeFilter,
                pressureRangeFilter,
                setPressureRangeFilter,
                heatRecoveryFilter,
                setHeatRecoveryFilter,
                soundAbsorptionFilter,
                setSoundAbsorptionFilter,
                deliveryControlFilter,
                setDeliveryControlFilter,
                coolingFilter,
                setCoolingFilter,
                otherFilter,
                setOtherFilter,
                currentCategoryName,
                setCurrentCategoryName,
                currentCategoryId,
                setCurrentCategoryId,
                isLoading,
                setIsLoading,
                currentOffset,
                setCurrentOffset,
                compressionModeUnits,
                setCompressionModeUnits,
                motorPowerMinFilter,
                setMotorPowerMinFilter,
                motorPowerMaxFilter,
                setMotorPowerMaxFilter,
                fedMinFilter,
                setFedMinFilter,
                fedMaxFilter,
                setFedMaxFilter,
                isFiltersSubmit,
                setIsFiltersSubmit,
                isMobileView,
                setIsMobileView,
                isProductsSearchFiltersOpened,
                setIsProductsSearchFiltersOpened,
                categoryNamesWithId,
                setCategoryNamesWithId,
                isAllFiltersClear,
                setIsAllFiltersClear,
                motorPowerMaxValue,
                fedMaxValue,
            }}
        >
            {children}
        </ProductSearchResultsContext.Provider>
    );
};
